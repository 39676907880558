var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Procurar")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Procurar","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"id":"table_company_immobile","columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm,
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'nameHide')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.avatar}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])],1):_vm._e(),(props.column.field === 'projectName')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.projectName)+" ")]):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(_vm.statusVariantName(props.row.status))+" ")])],1):(props.column.field === 'action')?_c('span',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],attrs:{"title":"Visualizar","to":{
            name: 'adm-public-immobile',
            params: { company: props.row, disable: 0, disableProject: 1 },
          }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Mostrando ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" Resultados ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{attrs:{"styleClass":"table_empty_data"},slot:"emptystate"},[_c('div',{staticClass:"table_empty_data"},[_vm._v(" Sem dados ")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }